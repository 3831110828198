/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'pXHQoXhujTEJ9oa5WQDB1K.png': { uri: '/images/pXHQoXhujTEJ9oa5WQDB1K.png' },
'rspBKTeBpFxZUN2Uur9XRQ.png': { uri: '/images/rspBKTeBpFxZUN2Uur9XRQ.png' },
'7q6eGGvA7vg1qRZZKvZMCR.png': { uri: '/images/7q6eGGvA7vg1qRZZKvZMCR.png' },
'tBnEfMC5buqucHo2DQ87Cs.png': { uri: '/images/tBnEfMC5buqucHo2DQ87Cs.png' },
'uA6LUcnKd3hf9s6Vbhb5Be.png': { uri: '/images/uA6LUcnKd3hf9s6Vbhb5Be.png' }
}

export default imageStaticSourcesByFileName
